import { MDXRenderer } from 'gatsby-plugin-mdx';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { helmetJsonLdProp } from 'react-schemaorg';
import Article from '../components/core/article';
import TechIcon from '../components/shortcodes/tech-icon';
import getSiteMetadata from '../util/get-site-metadata';
import { makeArticle, makeRoot } from '../util/ld-json';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ExtraLinks = function ExtraLinks(_ref) { var partners = _ref.partners, siteUrl = _ref.siteUrl, siteName = _ref.siteName, siteMsg = _ref.siteMsg, sourceUrl = _ref.sourceUrl; return ___EmotionJSX(React.Fragment, null, siteUrl ? ___EmotionJSX("p", null, siteMsg || "You can see it in action", " ", siteName ? "at: " : null, ___EmotionJSX("a", { href: siteUrl }, siteName || "here"), ".") : null, partners ? ___EmotionJSX("p", null, "I built this project with", " ", partners.map(function (partner, i) { return ___EmotionJSX(React.Fragment, { key: partner.ref }, partners.length > 1 && i === partners.length - 1 ? " and " : null, ___EmotionJSX("a", { href: partner.url }, partner.name), i === partners.length - 1 ? "." : null, partners.length > 2 && i !== partners.length - 1 ? "," : null); })) : null, sourceUrl ? ___EmotionJSX("p", null, "You can view the code for this project ", ___EmotionJSX("a", { href: sourceUrl }, "here"), ".") : null); };
var templates = { blog: Article, projects: Article };
var BlogPost = function BlogPost(_ref2) { var data = _ref2.data; var _getSiteMetadata = getSiteMetadata(), siteUrl = _getSiteMetadata.siteUrl; if (!data.mdx || !data.mdx.frontmatter || !data.mdx.body) {
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Article, { pageTitle: "ERROR" }, "Unable to render blog post. Expected data is not present."), ___EmotionJSX("code", { className: "language-json" }, JSON.stringify(data, null, 2)));
} var _data$mdx = data.mdx, frontmatter = _data$mdx.frontmatter, body = _data$mdx.body, excerpt = _data$mdx.excerpt, headings = _data$mdx.headings, fields = _data$mdx.fields; var Component = templates.blog; if (fields && fields.category && fields.category in templates) {
    Component = templates[fields.category];
} return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Helmet, { script: [helmetJsonLdProp(makeRoot(makeArticle(siteUrl, data.mdx)))] }), ___EmotionJSX(Helmet, null, ___EmotionJSX("meta", { name: "description", content: excerpt })), ___EmotionJSX(Component, { pageTitle: fields.title, subtitle: frontmatter.subtitle || undefined, technologies: ___EmotionJSX(React.Fragment, null, (frontmatter.technologies || []).map(function (t) { return ___EmotionJSX(TechIcon, { key: t.ref, refName: t.ref }); })), date: fields.date, headerImg: frontmatter.heroImage, headerImgAlign: frontmatter.heroImageAlign || undefined }, ___EmotionJSX(MDXRenderer, { headings: headings }, body), ___EmotionJSX(ExtraLinks, frontmatter))); };
var query = "88694717";
export default BlogPost;
